<template scoped>
  <Panel-CRUD
    ref="crud"
    :endpoint="endpoint"
    :toPage="toPage"
    :resourceServer="resourceServer"
    :resourceId="resourceId"
    title="Flow"
    :create="create"
    :parameter="parameter"
    :resourceServerApi="false"
    apiPath="Backend/Flow"
  >
    <template>
      <el-table-column prop="manufactoryName" label="Manufactory Name">
      </el-table-column>
      <el-table-column prop="productName" label="Product Name">
      </el-table-column>
      <el-table-column prop="name" label="Name"> </el-table-column>
      <el-table-column prop="buildStatus" label="Build Status">
        <template slot-scope="scope">
          <el-tooltip
            class="item"
            effect="dark"
            :content="scope.row.buildMessage"
            placement="right"
          >
            <el-tag :type="getBuildType(scope.row.buildStatus)">{{
              scope.row.buildStatus
            }}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column prop="enable" label="Live Mode">
        <template slot-scope="scope">
          <el-switch
            @change="$refs.crud.setEnable(scope.row)"
            v-model="scope.row.enable"
            active-color="#13ce66"
            inactive-color="#ff4949"
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="action" label="Action">
        <template slot-scope="scope">
          <el-row>
            <el-col :span="24">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="right"
              >
                <el-button
                  @click="edit(scope.row)"
                  icon="el-icon-edit"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Editor"
                placement="right"
              >
                <el-button
                  @click="editor(scope.row)"
                  icon="el-icon-guide"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Compile"
                placement="right"
              >
                <el-button
                  @click="compile(scope.row)"
                  icon="el-icon-video-play"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Copy Id"
                placement="right"
              >
                <el-button
                  @click="$refs.crud.copyId(scope.row)"
                  icon="el-icon-document-copy"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
            <el-col :span="24" :style="{ marginTop: '10px' }">
              <el-tooltip
                class="item"
                effect="dark"
                content="Delete"
                placement="right"
              >
                <el-button
                  type="danger"
                  @click="$refs.crud.remove(scope.row)"
                  icon="el-icon-delete"
                  circle
                ></el-button>
              </el-tooltip>
            </el-col>
          </el-row>
        </template>
      </el-table-column>
    </template>
  </Panel-CRUD>
</template>
<script>
export default {
  props: {
    endpoint: String,
    toPage: Function,
    resourceServer: String,
    resourceId: String,
    parameter: Object,
  },
  components: {
    "Panel-CRUD": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-List.vue"),
  },
  methods: {
    getBuildType: function (status) {
      switch (status) {
        case "Fail":
          return "danger";
        case "Success":
          return "success";
      }

      return "info";
    },
    edit: function (item) {
      this.toPage("components/Flow/Portal-Flow-CreateUpdate.vue", {
        id: item.id,
        filter: this.$refs.crud.filter,
      });
    },
    create: function () {
      this.toPage("components/Flow/Portal-Flow-CreateUpdate.vue", {
        filter: this.$refs.crud.filter,
      });
    },
    editor: function (item) {
      this.toPage("components/FlowLogic/Portal-FlowLogic.vue", {
        flowId: item.id,
        productId: item.productId,
        filter: this.$refs.crud.filter,
      });
    },
    compile: function (item) {
      this.$plugin.request
        .post(`${this.endpoint}/api/Backend/Flow/${item.id}/Compile`)
        .then(function () {});
    },
  },
};
</script>